import React, { useRef} from 'react';
import './App.css';
//import {Tile} from './Components/tile';
//import {Grid} from './Components/grid';
import SinglePage from './Components/SinglePage';
import IntroductionPage from './Components/IntroductionPage';
import InterestsPage from './Components/InterestsPage';
import AboutPage from './Components/AboutPage';
import ProjectPage from './Components/ProjectPage';
import ExperiencesPage from './Components/ExperiencesPage';
import InfoBar from './Components/InfoBar';
import {motion} from 'framer-motion';
import messages from './Data/messages.json';
import projects from './Data/projects.json';
import works from './Data/works.json';
import calculatorImg from './Images/calculator.png';
import FPNPaperImg from './Images/FPN-paper.png';





function App() {
  var introPageActive = true;
  const containerRef = useRef(null);
  const projectTitles = projects["projects titles"];
  const projectContents = {};
  Object.entries(projects["projects content"]).forEach(([projectName, content]) => {
    projectContents[projectName] = content.join(" ");
  });
  const projectKeywords = projects["project keywords"];
  const projectImages = {
    "WEB CALCULATOR": calculatorImg,
    "MULTI-SIZE SMALL ANCHORS AND PENALIZED IOU FOR FACE MASK DETECTION": FPNPaperImg
  
  };
  // const progressFactor = useTransform(scrollY, [0,1], [0,5]);

  return (
    <div className="App">
      <InfoBar progress={""}/>
      <div className="pages" ref={containerRef} >        
        <SinglePage label={"intro"}>
          <IntroductionPage author={"Yexuan Gao"} active={introPageActive} />
        </SinglePage>
       
        <motion.div
           className={`accent-shape`}
           whileHover={{
            scale: [null, 1.05], 
            transition: {
              type: "spring",
              duration: 0.8,
              bounce: 0.5
            }
           }}
           />
        <SinglePage label={"about"}>
          <AboutPage content={messages} />
        </SinglePage>
        <SinglePage label={"interests"}>
          <InterestsPage />
        </SinglePage>
        {projectTitles.map((title) => (
          <SinglePage label={"project"} key={title}>
            <ProjectPage projectName={title} content={projectContents[title]} keywords={projectKeywords[title]} image={projectImages[title]}/>
          </SinglePage>
        ))}
        
        
        <SinglePage label={"work-experiences"}>
          <ExperiencesPage works={works}/>
        </SinglePage>

        

      </div>
    </div>
  );
}

export default App;
