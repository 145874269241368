import React from 'react';
import './ProjectPage.css';
import {motion} from 'framer-motion';

const ProjectPage = ({projectName, content, keywords, image}) => {

  const projectVariants = {
    hLineNotInView: {
      scaleX: 0
    },
    hLineInView: {
      scaleX: 1,
      transition: {
        type: 'linear',
        duration: 0.7,
        ease: 'easeInOut'
      }
    },
    titleNotInView: {
      opacity: 0.5,
      x: -30
    },
    titleInView: {
      opacity: 1,
      x: 0,
      transition: {
        type: 'linear',
        duration: 0.7,
        ease: 'easeInOut'
      }
    },
    previewNotInView: {
      opacity: 0.3,
      x: -30,
      y: -30
    },
    previewInView: {
      opacity: 0.7,
      x: 0,
      y: 0,
      transition: {
        duration: 0.7,
        type: 'linear',
        ease: 'easeInOut'
      }
    },
    textNotInView: {
      x: 30,
      y: 30
    },
    textInView: {
      x: 0,
      y: 0,
      transition: {
        duration: 0.7,
        type: 'linear',
        ease: 'easeInOut'
      }
    }
  }
  return (
    
    <div className="project-page">
      <div className="project-name">
        <motion.div className="h-line" 
          initial="hLineNotInView"
          whileInView="hLineInView"
          variants={projectVariants}
          viewport={{margin: "0px 0px 0px 100px"}}
        />
        <motion.div initial="titleNotInView"
          whileInView="titleInView"
          variants={projectVariants}
          viewport={{margin: "0px 0px 0px 100px"}}
        >
          {projectName}
        </motion.div>
      </div>
      <motion.div className="project-preview"
        initial="previewNotInView"
        whileInView="previewInView"
        variants={projectVariants}
        viewport={{margin: "0px 0px 0px 100px"}}
      >
        <img src={image} alt="mock-project" />
      </motion.div>
      <motion.div className="project-intro"
        initial="textNotInView"
        whileInView="textInView"
        variants={projectVariants}
        viewport={{margin: "0px 0px 0px 100px"}}
      >
        <p> {content}</p>
      
        <div className="project-keywords">
          {keywords.map((keyword) => (
            <span className="keyword" key={keyword}>{keyword}</span>

          ))}
        </div>
      </motion.div>
    </div>

  );
};

export default ProjectPage;