import React from "react";
import {useRef} from 'react';
import "./AboutPage.css";
import { motion , useScroll, useTransform} from 'framer-motion';

const AboutPage = ({content}) => {
  const contentRef = useRef(null);
  const contentAnimation = {
    offView: {
      y: 50,
      opacity: 0.5
    },
    inView: i => ({
      y: 0,
      opacity: 1,
      transition: {duration: 0.8, type: "tween", ease: "easeInOut", delay: i * 0.2}
    }),
    onhover: {
      scale: [null, 1.01, 1],
      rotate: 5,
      transition: {duration: 1.5, type: "spring"}
    },
    titles: {
      x: [null, 20, 0],
      transition: {
        duration: 2,
        ease: "easeInOut",
        repeat: Infinity,
        repeatDelay: 1
      }
    }
  };

  const accentShape = {
    notInView: {
      scale: 0.95, opacity: 0.6, x:-10, y:10
    },
    inView: {
      scale: 1, opacity: 1, x:0, y: 0,
      transition: {duration: 0.8, type: 'spring', delay: 0.3}
    }
    
  }

  const buildMsg = (data) => {
    const messages = data.content;

    return messages.map((message, index) => (
          
            <motion.div className={`about-content message-${index} `} 
              
              key={index}
              custom={index}
              initial="offView"
              whileInView="inView"
              variants={contentAnimation}
              viewport={{margin: "100px 0px 0px 100px"}}
            >
              {message}
            </motion.div>
         
        ))
  };
  return (
    <div className="about-page" ref={contentRef}>
    {buildMsg(content)}
    <motion.div className="backdrop-shape-one-about"
      initial="notInView"
      whileInView="inView"
      variants={accentShape}
      viewport={{margin: "100px 0px 0px 100px"}}
    />
    <motion.div className="backdrop-shape-two-about"
      initial="notInView"
      whileInView="inView"
      variants={accentShape}
      viewport={{margin: "100px 0px 0px 100px"}}
    />
    </div>
  );
};

export default AboutPage;