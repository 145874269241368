import React from "react";
import './InfoBar.css';
import {AiFillGithub, AiFillLinkedin, AiOutlineMail} from "react-icons/ai";
import { IconContext } from "react-icons";
import {motion} from 'framer-motion';


const InfoBar = (props) => {

  return (
    <div className = "info-bar">
      <div className="icons-section">
      <IconContext.Provider value={{color: "white", size: "1.5rem"}}>
        <motion.div className="email-icon menu-icon" whileHover={{scale: 1.1, transition: {type: 'spring', stiffness: 100, damping: 5, duration: 0.6}}}>
          <a href="mailto: yexuan.gao@outlook.com">
            <AiOutlineMail />
          </a>
        </motion.div>
        <motion.div className="github-icon menu-icon " whileHover={{scale: 1.1, transition: {type: 'spring', stiffness: 100, damping: 5, duration: 0.6}}}>
          <a href="https://github.com/Brucegyx">
            <AiFillGithub />
          </a>
        </motion.div>
        <motion.div className="linkedin-icon menu-icon" whileHover={{scale: 1.1, transition: {type: 'spring', stiffness: 100, damping: 5, duration: 0.6}}}>
          <a href="linkedin.com/in/yexuan-gao-27aba0149">
            <AiFillLinkedin />
          </a>
        </motion.div>
        
      </IconContext.Provider>
      </div>
    </div>
  );
};

export default InfoBar;