import React from 'react';
import './SinglePage.css';
import {motion, useScroll, useTransform} from 'framer-motion';



const SinglePage = (props) => {
  const labelName = props.label;
  return (
    <div 
      className={`single-page ${labelName}`}
    > 
      {props.children} 
    </div>
  );
};

export default SinglePage;