import React from 'react';
import './InterestsPage.css';
import {motion} from 'framer-motion';
import {SiReact, SiNodedotjs, SiPython, SiPhp, SiAmazonaws,SiMicrosoftsqlserver, SiGit, SiMysql, SiSwift, SiDotnet} from 'react-icons/si';
import { BiNetworkChart } from 'react-icons/bi';
import { FaJava } from 'react-icons/fa';
import { TbCloudComputing}  from 'react-icons/tb';
import { IconContext } from 'react-icons';

const skillsPage = {
    offView: {
      y: 50,
      opacity: 0.5,
      scaleY: 0.9
    },
    inView: {
      y: 0,
      opacity: 1,
      scaleY: 1,
      transition: {
        duration: 0.8, 
        type: "tween", 
        ease: "easeInOut"
      }
    },
    areaOffView: {
      y: 20,
      opacity: 0
    },
    areaInView: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8, 
        ease: "easeInOut", 
        delay: 0.3,
        staggerChildren: 0.5
      }
    }
}

const InterestsPage = (props) => {

  /*const buildList = (list) => {
    if (props.type === "addList"){
        return list.map(item => (

            <label className="area-one" key={item.name}> item.text</label>
        ))
      } else {return null}

  }*/

  
  return (
    <div className="skills-area">
      <motion.div className="title"
        variants={skillsPage}
        initial="offView"
        whileInView="inView"
      >
        <span className="tl">Skills <br></br> & <br></br>Interests</span>
      </motion.div>
      <motion.div className="aoi"
        variants={skillsPage}
        initial="areaOffView"
        whileInView="areaInView"
      >
        <div className="area-one areas">
          <div className="aoi-title"> 
             GOOD
           </div>
          <div className="aoi-content">
          <IconContext.Provider value={{className:"skill-icon", size:"2.5rem", color: "#006ad0" }}>
            <span className="single-skill ">
              <SiReact />
              <h5>React</h5>
            </span>
            
            <span className="single-skill ">
            <SiPhp />
              <h5>PHP </h5>
            </span>
            <span className="single-skill">
              <SiMicrosoftsqlserver />
                <h6>MS SQL Server </h6>
            </span>
            <span className="single-skill ">
              <SiPython />
              <h5>Python</h5>
            </span>
           
            <span className="single-skill ">
              <SiGit />
              <h5>Git</h5>
            </span>
            </IconContext.Provider>
            
          </div>
        </div>
        <div className="area-two areas">
          <div className="aoi-title"> 
             FAMILIAR
           </div>
          <div className="aoi-content">
            <IconContext.Provider value={{className:"skill-icon", size:"2.5rem", color: "#006ad0" }}>
            <span className="single-skill">
              <SiNodedotjs />
              <h5>Node.js</h5>
            </span>
            <span className="single-skill">
              <SiMysql />
              <h5>MySQL</h5>
            </span>
            <span className="single-skill">
              <SiDotnet />
              <h5>.NET</h5>
            </span>
            <span className="single-skill ">
              <FaJava />
              <h5>Java</h5>
            </span>
            <span className="single-skill ">
              <SiAmazonaws />
              <h5>AWS</h5>
            </span>
            <span className="single-skill ">
              <BiNetworkChart />
              <h5>CNN</h5>
            </span>
            </IconContext.Provider>
          </div>
        </div>
        <div className="area-three areas">
          <div className="aoi-title"> 
            EXPLORE 
           </div>
          <div className="aoi-content">
            <IconContext.Provider value={{className:"skill-icon", size:"2.5rem", color: "#006ad0" }}>
            <span className="single-skill ">
              <SiSwift />
              <h5>Swift</h5>
            </span>
            <span className="single-skill ">
              <TbCloudComputing />
              <h6>Cloud Computing</h6>
            </span>
            </IconContext.Provider>
          </div>
        </div>
      </motion.div>
    
      

    </div>
  );
  
}

export default InterestsPage;
