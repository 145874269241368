
import React from "react";
import './ExperiencesPage.css';
import TabDisplay from "./TabDisplay";
import { motion } from 'framer-motion';
import {  AiOutlineDownload } from 'react-icons/ai';
import { IconContext } from "react-icons";

const workPageVariants = {
  notInView: {
    opacity: 0.5, scale: 0.9
  },
  inView: {
    opacity: 1, scale: 1,
    transition: {duration: 0.7, type: 'linear'}
  },
  onHover: {
    linearGradient: [null, "#d3d3d3","#000000"],
    transition: {type: 'tween', duration: 0.5, ease: "linear"}
  }
}

const ExperiencesPage = ({works}) => {
  return (
    <div className="experiences-page">
      <motion.span className="exp-page-title"
        initial="notInView"
        whileInView="inView"
        variants={workPageVariants}
        viewport={{once: true, margin: "0px 0px 0px 50px"}}
      >
        Where I have worked
      </motion.span>
      <motion.div className="tab-display-section"
        initial="notInView"
        whileInView="inView"
        variants={workPageVariants}
        viewport={{once: true, margin: "0px 0px 0px 100px"}}
      >
        <TabDisplay works={works}/>
      </motion.div>
      <motion.button className="resume-button"
        whileHover="onHover"
        variants={workPageVariants}
      >
      
        <span>My Resume </span>
        <IconContext.Provider value={{size: "1.5rem"}}>
          <AiOutlineDownload />
        </IconContext.Provider>
      </motion.button>

      
    </div>
  );
};

export default ExperiencesPage;