import React, {useState} from 'react';
import './TabDisplay.css';
import { motion } from 'framer-motion';

const display = {
  start: {
    y: 10,
    opacity: 0
  },
  final: {
    y: 0,
    opacity: 1,
    transition:{
      duration: 1, 
      ease: "easeInOut",
      staggerChildren: 0.5
    }
  }

}

const workContent = {
  start: {opacity: 0},
  final: {opacity: 1}
}

const TabDisplay = ({works}) => {
  const workTimeList = Object.keys(works);
  const workTitleList = [];
  const workCompanyList = [];
  const workDetailList = [];

  Object.entries(works).forEach(([time, content]) => {
    workTitleList.push(content["title"]);
    workCompanyList.push(content["company"]);
    workDetailList.push(content["detail"]);
  });
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const handleClick = (e) => {
    const clickedTabIndex = e.target.id;
    setActiveTabIndex(parseInt(clickedTabIndex));
  }

  const getActiveTabContent = (ind) => {
    return (
      <motion.div key={ind} 
        className="work-content" 
        initial="start"
        animate="final"
        variants={display}
      >
        <motion.div className="job-company" variants={workContent}>{workCompanyList[ind]}</motion.div>
        <motion.div className="job-title" variants={workContent}></motion.div>
        <motion.ul className='job-details' variants={workContent}>
          {
            workDetailList[ind].map((line, index) => (
              <li key={index}>{line}</li>
            ))
          }
        </motion.ul>
      </motion.div>);
  }


  const buildTabs = (tablist) => {
    return tablist.map((tab, index) => (
        <div className="tab" key={tab}>
          <motion.button 
            className={`time-tab-btn`} 
            id={index.toString()}  
            onClick={handleClick}
            animate={{backgroundColor: index === activeTabIndex ? "#006ad0" : "#0a2f80"}}
            transition={{type: 'linear', duration: 0.6, ease: 'easeInOut'}}
          >
            {tab}
          </motion.button> 
          {activeTabIndex === index && (
            <motion.div  initial={{scaleY: 0}} animate={{scaleY: 1, originY: 0}} transition={{duration: 0.6, type: 'spring', ease: "easeInOut"}} className="tabSideline"/>
          )}
        </div>
    )
  );

  }

  return (
    <div className="tab-display">
      <div className="time-tabs">
        {buildTabs(workTimeList)}

      </div>
      
      {getActiveTabContent(activeTabIndex)}
    </div>
  );
};

export default TabDisplay;