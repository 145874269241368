
import React from 'react';
import {motion} from 'framer-motion';
import './IntroductionPage.css';

const introPageText = {
  offScreen: {
    x: 50
  },
  onScreen: i => ({
    x: 0,
    transition: {
      type: "spring",
      duration: 1.2,
      ease: "easeOut",
      delay: i * 0.1
    }
  }),
  authorOffScreen: {
    y: 20,
    opacity: 0, 
  },
  authorOnScreen: {
    y: 10,
    opacity: 1,
    transition: {duration: 0.8, ease: "easeInOut", delay: 0.5}
  }
};

const accentShapeOne= {
  notInView: {
    x: -10,
    opacity: 0
  },
  inView: {
    x: 20,
    opacity: 1,
    transition: {
      duration: 1.2,
      ease: "easeInOut",
      delayChildren: 0.5
    }
  }
}

const accentShapeTwo= {
  notInView: {
    x: -20,
    opacity: 0.3
  },
  inView: {
    x: 10,
    opacity: 1,
    transition: {
      duration: 0.7,
      ease: "easeInOut"
    }
  }
}


const IntroductionPage = (props) => {
  const authorName = props.author;
  const title = [ "Welcome", "To My", "Space"];


  return (
    <div className="introduction-page">
      <motion.div 
        className={`title-text`}
      >
          {title.map((text, index) => {
            return (
              <motion.div 
                className={`title-text-word word${index} `} 
                key={text}
                custom={index}
                initial="offScreen"
                whileInView="onScreen"
                variants={introPageText}
              >
              {text}
              </motion.div>
            );
          })}
        <motion.div 
          className="author-name" 
          initial="authorOffScreen"
          whileInView="authorOnScreen"
          variants={introPageText}
        >
          {`By ${authorName}`}
        </motion.div>
      </motion.div>
      <motion.div className="backdrop-shape"
        variants={accentShapeOne}
        initial="notInView"
        whileInView="inView"
      >
      <motion.div className="backdrop-shape-two" 
        variants={accentShapeTwo}
      />
      </motion.div>

      
    </div>
  );
};

export default IntroductionPage;